import { Location } from "@angular/common";
import { Observable, Subject, of } from "rxjs";
import { Campaign, CampaignResponse, SaleStatus } from "@models/campaign.model";
import { Injectable } from "@angular/core";
import { delay, map, mergeMap } from "rxjs/operators";

import { CustomerInfoService } from "./customer-info.service";
import { DataService } from "./data.service";
import { GoogleAnalyticServiceService } from "@app/helpers/google-analytic-service.service";
import { HttpClient } from "@angular/common/http";
import { LoaderInfo } from "@app/models/loader.model";
import { OtpService } from "./otp.service";
import { PortalService } from "@app/modules/home/services/portal.service";
import { ProductService } from "./product.service";
import { ActivatedRoute, Router } from "@angular/router";
import { SessionStorageService } from "./session-storage.service";
import { capitalize } from "@app/helpers/utils";
import { environment as env } from "../../environments/environment";

interface CampaignParent {
  data: CampaignResponse;
}

@Injectable({
  providedIn: "root",
})
export class CampaignService {
  private _waitingMessageSource = new Subject<string>();
  waitingMessage$ = this._waitingMessageSource.asObservable();
  private _callUsStatusSource = new Subject<boolean>();
  callUsStatus$ = this._callUsStatusSource.asObservable();
  private _loaderNoInterceptorSource = new Subject<boolean>();
  loaderNoInterceptor$ = this._loaderNoInterceptorSource.asObservable();
  private _successSaleFlagSource = new Subject<boolean>();
  successSaleFlag$ = this._successSaleFlagSource.asObservable();
  
  private loaderInfo: LoaderInfo = {
    show: true,
    message: "",
    showCallUs: false,
  };

  public typesListVC = ["VC", "F2F"];
  private routeFlow: string;
  public defaultProductID = "TRAV01";
  public UTMProduct: string;
  static readonly PERSONAL_ID_TYPES = {
    CEDULA: "C"
  }
  static readonly SALE_QPARAMS = {
    VS: "o",
    VC: "operation_id"
  }
  static readonly SALE_TYPES = {
    VL: "VL",
    VS: "VS",
    VC: "VC",
    F2F: "F2F",
    RN: "F2FRN"
  }
  static readonly PORTAL_MODE = {
    ECOMMERCE: "ECOMMERCE",
    CC: "CALLCENTER",
    F2F: "F2F"
  }
  static readonly PREFIX_SALE = {
    CC: "vl-callcenter",
    VL: "vl-cliente",
    VS: "vs-cliente",
    VC: "vc-cliente",
    F2F: "vc-f2f",
    F2FRN: "vc-f2frn",
  };
  static readonly CANAL_SALE = {
    CC: "CALLCENTER",
    VL: "CLIENTE",
    VS: "DIRECTO",
    VC: "BVM",
    F2F: "F2F",
    F2FRN: "REFERIDO",
  };
  public CTAEmailShow: boolean = false;
  public saleStatus: SaleStatus = {
    type: "VL",
    prefixUrl: CampaignService.PREFIX_SALE.VL,
    channel: CampaignService.CANAL_SALE.VL,
    offerId: "",
    offerExpired: false,
  };
  public portalData = {
    ramo_name: null,
    coverage_name: null,
  };
  public identificationNumber: string;
  public data: Campaign;
  public productPlanSelectedInfo: any;
  public paymentMethods: any[] = [];
  public stepInfo: any;
  public allSteps: any[];
  public currentSteps: any[];
  public firstRequestCampaign: boolean = true;
  public canValidate = false;
  public isValidID = true;
  public catalogsData: any = null;
  public additionalData: any = null;
  public holidayData: any = null;
  public updateStep: number = 1;
  public showBeneficiariesCtrl: boolean = false;
  public allowBeneficiaries: boolean = false;
  public initialPersonalInfoData: any;
  public personalInfoData: any;
  public beneficiariesInfoData: any[];
  public paramsData: any;
  public loaderMessageDefault =
    "Estamos procesando tu información, en unos minutos ya podrás contar con los beneficios de tu cobertura de seguro.";
  public loaderMessage = this.loaderMessageDefault;
  public productSelectedIndex = 0;
  public firstSessionQuoteMobile = true;
  public campaignService;
  public payloadGuard;
  public previousUrl: string = "";
  public currentUrl: string = "";
  public tryingUrl: string = "";
  constructor(
    private httpClient: HttpClient,
    private otpService: OtpService,
    private customerService: CustomerInfoService,
    private productService: ProductService,
    private portalService: PortalService,
    private router: Router,
    private _sessionSt: SessionStorageService,
    private googleAnalyticService: GoogleAnalyticServiceService,
    private location: Location,
    private dataService: DataService
  ) {
    const _stCampaignData = JSON.parse(sessionStorage.getItem("chubbData_front"));
    
    if (_stCampaignData?.saleType === CampaignService.SALE_TYPES.VL ) {
      this.data = {
        id_offer: _stCampaignData?.offer_id
      };
      this.setSaleStatus({
        type: _stCampaignData?.saleType || CampaignService.SALE_TYPES.VL,
        offerId: _stCampaignData?.offer_id
      });
    } else {
      this.setSaleStatus({
        type: _stCampaignData?.saleType || CampaignService.SALE_TYPES.VL
      });
    }
  }

  setLoaderInfo(info) {
    this.loaderInfo = {
      ...this.loaderInfo,
      ...info,
    };
  }

  getLoaderInfo() {
    return this.loaderInfo;
  }

  sendWaitingMessage(message: string = this.loaderMessageDefault) {
    this._waitingMessageSource.next(message);
  }

  sendLoaderNoInterceptor(message: boolean) {
    this._loaderNoInterceptorSource.next(message);
  }

  sendSuccessSaleMessage(message: boolean) {
    this._successSaleFlagSource.next(message);
  }

  public redirectIfCampaignNotResolved() {
    const paramsObj = this._sessionSt.getParamsObj();
    if (this.saleStatus.type == "VL") {
      this.router.navigate(["/"]);
    } else {
      this.router.navigate(["/"], {
        queryParams: paramsObj,
      });
    }
  }

  public setCatalogsData(data) {
    this.catalogsData = {
      ...this.catalogsData,
      ...data,
    };
  }
  public isCampaignDataExist() {
    return this.data;
  }
  public getCatalogsData(input) {
    return this.catalogsData[input];
  }

  public setProductInCampaignData(input) {
    this.data = {
      ...this.data,
      product_id: input,
    };
  }

  public updateRangeAge(input, prod) {}

  public cleanData(res: CampaignParent, idOffer): Campaign {
    this.setData({ ...res.data.data, id_offer: idOffer });
    return this.getData();
  }
  public cleanDraftData(res) {
    const result = typeof res === "string" ? JSON.parse(res) : res;
    this.saleStatus = {
      ...this.saleStatus,
      type: result.SaleType,
      offerId: result.offer_id,
      operationId: result.operation_id,
    };
    if (this.setSaleStatus) {
      this.setSaleStatus(this.saleStatus);
    } else {
      this.campaignService.setSaleStatus(this.saleStatus);
    }
    this.setData({ ...result, id_offer: result.offer_id });
    return this.getData();
  }
  public getDefaultProductID() {
    return this.defaultProductID;
  }
  public setIsValidID(input) {
    this.isValidID = input;
  }
  public getIsValidID() {
    return of({ isValidID: this.isValidID }).pipe(delay(400));
  }
  public getData() {
    return this.data;
  }
  public getAdditionalData() {
    return this.additionalData;
  }
  public setData(data) {
    this.data = {
      ...this.data,
      ...data,
    };
  }
  public getAgeFromBirthdate(birthInput) {
    const MS_PER_YEARS = 1000 * 60 * 60 * 24 * 365.2425;
    const myDate = new Date();
    const birthdate = new Date(birthInput);
    var years = Math.floor(
      (myDate.getTime() - birthdate.getTime()) / MS_PER_YEARS
    );
    return years;
  }
  public getRangeAgeFromSingleAge(ageInput) {
    if (ageInput >= 18 && ageInput <= 40) {
      return "18 - 40";
    } else if (ageInput >= 41 && ageInput <= 55) {
      return "41 - 55";
    } else if (ageInput >= 56 && ageInput <= 65) {
      return "56 - 65";
    } else if (ageInput >= 66) {
      return "66 - *";
    }
    return;
  }

  public getRangeAgeForReload(ageInput) {
    if (ageInput >= 18 && ageInput <= 40) {
      return "40";
    } else if (ageInput >= 41 && ageInput <= 55) {
      return "55";
    } else if (ageInput >= 56 && ageInput <= 65) {
      return "65";
    } else if (ageInput >= 66) {
      return "66";
    }
    return;
  }

  public setDataFromCustomerInfo(inputData) {
    if (!this.data?.range_age_type) {
      const actualAge = this.getAgeFromBirthdate(inputData.birthdate);
      this.data = {
        ...this.data,
        range_age_type: this.getRangeAgeFromSingleAge(actualAge),
      };
      /*this.dataService.updatedDataSelection({
        ageRange: this.getRangeAgeForReload(actualAge),
      });*/
    }
  }
  public setAdditionalData(data = null) {
    if (data) {
      this.additionalData = {
        ...this.getAdditionalData(),
        ...data,
      };
    } else {
      this.additionalData = null;
    }
  }

  public resetData() {
    this.data = null;
    this._sessionSt.resetSessionData({
      saleType: CampaignService.SALE_TYPES.VL,
      saleTypePrefixUrl: CampaignService.PREFIX_SALE.VL,
    });
    this.customerService.resetData();
  }

  public resetAdditionalData() {
    this.additionalData = null;
    this._sessionSt.resetSessionData({
      saleType: this.saleStatus.type,
      saleTypePrefixUrl: this.saleStatus.prefixUrl,
      offer_id: this.saleStatus.offerId,
      operation_id: this.saleStatus.operationId,
    });
  }

  public setCurrentSteps(steps) {
    if (this.allowBeneficiaries) {
      this.currentSteps = steps;
    } else {
      this.currentSteps = steps.filter((e) => !e.beneficiariesStep);
    }
  }

  public getCurrentSteps() {
    return this.currentSteps;
  }

  public setAllSteps(steps) {
    this.allSteps = steps;
  }

  public getAllSteps() {
    return this.allSteps;
  }

  public checkPrefixBySaleType(input = "VL") {
    const saleTypesMap = [
      {
        type: "VL",
        prefix: CampaignService.PREFIX_SALE.VL,
      },
      {
        type: "VS",
        prefix: CampaignService.PREFIX_SALE.VS,
      },
      {
        type: "VC",
        prefix: CampaignService.PREFIX_SALE.VC,
      },
      {
        type: "F2F",
        prefix: CampaignService.PREFIX_SALE.F2F,
      },
      {
        type: "F2FRN",
        prefix: CampaignService.PREFIX_SALE.F2FRN,
      },
    ];
    const result = saleTypesMap.find((i) => i.type === input);
    return result?.prefix || CampaignService.PREFIX_SALE.VL;
  }

  public checkCanalSaleBySaleType(input = "VL") {
    const channelTypesMap = [
      {
        type: "VL",
        channel: CampaignService.CANAL_SALE.VL,
      },
      {
        type: "VS",
        channel: CampaignService.CANAL_SALE.VS,
      },
      {
        type: "VC",
        channel: CampaignService.CANAL_SALE.VC,
      },
      {
        type: "F2F",
        channel: CampaignService.CANAL_SALE.F2F,
      },
      {
        type: "F2FRN",
        channel: CampaignService.CANAL_SALE.F2FRN,
      },
    ];
    const result = channelTypesMap.find((i) => i.type === input);
    return result?.channel || CampaignService.CANAL_SALE.VL;
  }

  public setSaleStatus(data = null) {
    let prefix = CampaignService.PREFIX_SALE.VL;
    let channel = CampaignService.CANAL_SALE.VL;
    const isCallCenter = this.portalService.portalMode === "CALLCENTER";
    const changePortalModeF2F2VL = this.portalService.portalMode === CampaignService.PORTAL_MODE.F2F && data?.type === CampaignService.SALE_TYPES.VL;
    //WIP OPID
    if (isCallCenter) {
      prefix = CampaignService.PREFIX_SALE.CC;
      channel = CampaignService.CANAL_SALE.CC;
    } else if (data?.type) {
      prefix = this.checkPrefixBySaleType(data.type);
      channel = this.checkCanalSaleBySaleType(data.type);
      if (changePortalModeF2F2VL) {
        this.portalService.updatePortalMode(CampaignService.PORTAL_MODE.ECOMMERCE);
      }
    }
    this._sessionSt.set_SessionStorageFromObject({
      saleType: data?.type || this.saleStatus.type,
      saleTypePrefixUrl: prefix,
      flowType: prefix,
      channel,
      offer_id: data?.offerId,
      operation_id: data?.operationId,
    });
    this.saleStatus = {
      ...this.saleStatus,
      ...data,
      prefixUrl: prefix,
      channel,
      offerId: data?.offerId,
      operationId: data?.operationId,
    };
  }

  public getSaleStatus() {
    return this.saleStatus;
  }

  public setPortalData(data) {
    this.portalData = data;
  }

  public getPortalData() {
    return this.portalData;
  }

  public setPreviousUrl(data) {
    this.previousUrl = data;
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }

  public setCurrentUrl(data) {
    this.currentUrl = data;
  }

  public getCurrentUrl() {
    return this.currentUrl;
  }

  public setTryingUrl(data) {
    this.tryingUrl = data;
  }

  public getTryingUrl() {
    return this.tryingUrl;
  }

  public setProductPlanSelectedInfo(data) {
    this.productPlanSelectedInfo = {
      ...this.productPlanSelectedInfo,
      ...data,
    };
  }

  public getProductPlanSelectedInfo() {
    return this.productPlanSelectedInfo;
  }

  public setStepInfo(data) {
    this.stepInfo = {
      ...this.stepInfo,
      ...data,
    };
  }

  public getStepInfo() {
    return this.stepInfo;
  }

  public setInitialPersonalInfoData(data) {
    this.initialPersonalInfoData = {
      ...data,
    };
  }

  public getInitialPersonalInfoData() {
    return this.initialPersonalInfoData;
  }

  public setPersonalInfoData(data) {
    this.personalInfoData = {
      ...this.personalInfoData,
      ...data,
    };
  }

  public getPersonalInfoData() {
    return this.personalInfoData;
  }

  public setBeneficiariesInfoData(data) {
    this.beneficiariesInfoData = [...data];
  }

  public getBeneficiariesInfoData() {
    return this.beneficiariesInfoData;
  }

  public setPaymentMethodsData(data) {
    this.paymentMethods = [...data];
  }

  public getPaymentMethodsData() {
    return this.paymentMethods;
  }

  public setRouteFlow(input) {
    this.routeFlow = input;
  }
  public getRouteFlow() {
    return this.routeFlow;
  }

  public setIdentificationNumber(input) {
    this.identificationNumber = input;
  }

  public getIdentificationNumber() {
    return this.identificationNumber;
  }

  public getFirstRequestCampaignStatus() {
    return this.firstRequestCampaign;
  }

  public setFirstRequestCampaignToFalse() {
    this.firstRequestCampaign = false;
  }

  public setInfoPlanCardUI(UIFn) {
    this[UIFn]();
  }
  public setIMEIPlanCardUI() {
    return {
      additionalPlanCardText: `IMEI - ${this.additionalData?.imei}`,
    };
  }
  public setAdditionalDataForUI(productId) {
    const fnMap = [
      {
        fromProduct: [
          "CELCOM",
          "CEDAME",
          "CEROME",
          "CELCOA",
          "CEDAAN",
          "CEROAN",
        ],
        fnUIData: "getDataUIForCelularProtegido",
      },
      {
        fromProduct: ["TRAV01"],
        fnUIData: "getDataUIForTravel",
      },
    ];
    const filterFn = fnMap.find((f) =>
      f.fromProduct.includes(productId)
    )?.fnUIData;
    if (filterFn) {
      this.setAdditionalData(this[filterFn]());
    } else {
      return;
    }
  }
  public getDataUIForCelularProtegido() {
    const data = this.getAdditionalData();
    const filterPlan = data.storage.find((s) => s.capacity == data.capacity);
    const capacityString = data.noShowCapacity ? "" : `/ ${data.capacity}`;
    const makeCapitalize = capitalize(data.make.toLowerCase());
    return {
      selectPlanTitle: `Tu dispositivo ${makeCapitalize} ${data.model} ${capacityString}`,
      selectPlanMethod: `${makeCapitalize} ${data.model} ${capacityString}`,
      selectPlanCTAModal: {
        modal: "NoYourDevice",
        text: "¿No es este tu dispositivo?",
        confirmText: `Si este no es tu dispositivo, debes ingresar nuevamente tus datos para recibir información para tu celular.`,
      },
      subjectInsuredTitle: "Dispositivo",
      subjectInsuredData: `${makeCapitalize} ${data.model}
${data.capacity}`,
      filterPlan: {
        code: +filterPlan.benefitLevel,
      },
    };
  }

  public getDataUIForTravel() {
    const data = this.getAdditionalData();
    let dependentLabels = [];
    if (data["qty-travelers"]) {
      dependentLabels.push(`Datos del viajero 1: (${data["own-age"]} años)`);
      if (data["qty-travelers"] >= 2) {
        Array.from({ length: data["qty-travelers"] - 1 }, (a, i) => {
          dependentLabels.push(
            `Datos del viajero ${i + 2}: (${data[`age-${i + 1}`]} años)`
          );
        });
      }
    }
    const {
      TotalPrime: discountFee,
      TotalPrimeExtended: marketFee,
      NetPremium: premiumFee,
      DiscountValue: savingsValue,
    } = data;
    const [discountDollars, discountCents] = discountFee.toFixed(2).split(".");
    const quoteResult = {
      discountFee,
      discountDollars,
      discountCents,
      premiumFee,
      marketFee,
      savingsValue,
    };
    const travelTypes = [
      {
        travelType: "Solo Ida",
        travelTypeEN: "One Way",
        travelCode: 1,
      },
      {
        travelType: "Ida y Regreso",
        travelTypeEN: "Round Trip",
        travelCode: 2,
      },
    ];
    const listMap = [
      {
        destinyType: "Nacional",
        weightRegion: 1,
        travelTypes: travelTypes,
      },
      {
        destinyType: "Internacional",
        weightRegion: 2,
        travelTypes: travelTypes,
      },
      {
        destinyType: "Internacional",
        weightRegion: 3,
        travelTypes: travelTypes,
      },
      {
        destinyType: "Internacional",
        weightRegion: 4,
        travelTypes: travelTypes,
      },
      {
        destinyType: "Internacional",
        weightRegion: 5,
        travelTypes: travelTypes,
      },
    ];
    if (data.destinyType == "Nacional") {
      const travelType = travelTypes.find(
        (t) => t.travelType == data.travelType
      );
      return {
        ...quoteResult,
        selectPlanTitle: "Encontramos lo mejor para ti",
        numDependents: data["qty-travelers"],
        dependentLabels,
        filterPlan: {
          travelRegionCode: 1,
          travelTypeCode: travelType.travelCode,
        },
      };
    }
    const countriesData = this.getCatalogsData("countries");
    const filterCountriesMax = countriesData
      .filter((c) => {
        const matchCountry = this.additionalData.destinations.find(
          (ic) => ic == c.Name_ES
        );
        return matchCountry;
      })
      .reduce(
        (max, current) => (+current.weight > +max?.weight ? current : max),
        { weight: 0 }
      );

    const filterPlan = listMap.find(
      (l) => l.weightRegion == filterCountriesMax.weight
    );
    const travelType = filterPlan.travelTypes.find(
      (t) => t.travelType == data.travelType
    );

    return {
      ...quoteResult,
      numDependents: data["qty-travelers"],
      dependentLabels,
      filterPlan: {
        travelRegionCode: filterPlan.weightRegion,
        travelTypeCode: travelType.travelCode,
      },
    };
  }

  public composeParams(data) {
    let resultStr = "";
    if (data.SaleType === "VS") {
      resultStr += `?o=${data.offer_id}`;
    } else if (data.SaleType === "VC" || data.SaleType === "F2F") {
      resultStr += `?operation_id=${data.operation_id}`;
    } else {
      resultStr = this.productService.getProductByName(data.product_id).url;
    }
    return resultStr;
  }

  getCampaignData(idOffer = ""): Observable<Campaign> {
    return this.httpClient
      .get<CampaignParent>(`${env.baseUrl}campaign`, {
        params: { id_offer: idOffer },
      })
      .pipe(map((data) => this.cleanData(data, idOffer)));
  }

  getLastRouteFlow(productRouteFlow, restRouteFlow) {
    let fullRouteFlow = productRouteFlow;
    if (restRouteFlow == "/select-plan") {
      fullRouteFlow += restRouteFlow;
    } else if (restRouteFlow == "/confirm-info") {
      fullRouteFlow += `/select-plan${restRouteFlow}`;
    } else if (restRouteFlow == "/confirmacion-pago") {
      fullRouteFlow += `/select-plan/confirm-info${restRouteFlow}`;
    }
    return fullRouteFlow;
  }

  setFeeZeroPromo(productId: string) {
    let promoObj = {
      ...JSON.parse(sessionStorage.getItem("queryParams")),
      fee_zero: (productId || this.data.product_id) + "0"
    }
    sessionStorage.setItem("queryParams", JSON.stringify(promoObj));
  }

  removeBGCampaignParams() {
    const paramsObj = JSON.parse(sessionStorage.getItem("queryParams"));
    if (paramsObj) {
      delete paramsObj.operation_id;
      delete paramsObj.o;
      sessionStorage.setItem("queryParams", JSON.stringify(paramsObj));
    }
  }

  getCampaignWithOTPData(inputData) {
    inputData.str_UTM =
      inputData.str_UTM && Object.keys(inputData.str_UTM).length
        ? {
          ...JSON.parse(sessionStorage.getItem("queryParams")),
          ...inputData.str_UTM
        }
        : JSON.parse(sessionStorage.getItem("queryParams"));
    var UTM_Data = [];
    for (var k in inputData.str_UTM) {
      UTM_Data.push({ Key: k, Value: inputData.str_UTM[k] });
    }
    const dataSend = {
      type: this.saleStatus.type,
      ...inputData,
      str_UTM: UTM_Data,
    };
    const dataOtp = {
      info: inputData.info,
      id_offer: inputData.id_offer,
      product_id: inputData.product_id,
    };
    return this.httpClient.post<any>(`${env.baseUrl}draft`, dataSend).pipe(
      map((data) => this.cleanDraftData(data.data)),
      mergeMap((data) =>
        this.otpService.sendOtpData({
          ...dataOtp,
          id_offer: this.data.id_offer,
        })
      )
    );
  }

  getCampaignWithOTPClientData(inputData) {
    return this.httpClient
      .post<CampaignParent>(`${env.baseUrl}draft`, {
        params: { id_offer: inputData.id_offer },
      })
      .pipe(
        map((data) => this.cleanData(data, inputData.id_offer)),
        mergeMap((data) => this.otpService.sendOtpData(inputData)),
        mergeMap((data) => this.customerService.getCustomerInfoData(inputData))
      );
  }

  loadCampaign(payload) {
    this.payloadGuard = payload;
  }

  getDraftData(input) {
    if(this.payloadGuard) {
      this.payloadGuard = null;
    }
    const url = "https://run.mocky.io/v3/3b60e6ea-fbe1-47c6-9ce4-9412a7ef85ec";
    const typesListVC = ["VC", "F2F"];
    const pathURL = typesListVC.includes(input.type) ? "canalsale" : "draft";
    input.str_UTM =
      input.str_UTM && Object.keys(input.str_UTM).length
        ? {
          ...JSON.parse(sessionStorage.getItem("queryParams")),
          ...input.str_UTM
        }
        : JSON.parse(sessionStorage.getItem("queryParams"));
    var UTM_Data = [];
    for (var k in input.str_UTM) {
      UTM_Data.push({ Key: k, Value: input.str_UTM[k] });
    }

    const inputData = typesListVC.includes(input.type)
      ? {
          operation_id: input.operation_id,
          str_UTM: UTM_Data,
        }
      : {
          ...input,
          str_UTM: UTM_Data,
        };
    return this.httpClient
      .post<any>(`${env.baseUrl}${pathURL}`, inputData)
      //.post<any>(url, inputData)
      .pipe(
        map((data) => {
          if (this.sendSuccessSaleMessage) {
            this.sendSuccessSaleMessage(false);
          } else {
            this.campaignService.sendSuccessSaleMessage(false);
          }
          const campaignData = { ...data.data };
          /*const productInfo = this.productService.getProductByName(
            campaignData.product_id
          );*/
          this.data = {
            ...this.data,
            ...data.data,
            id_offer: campaignData.offer_id
          };
          this.saleStatus = {
            ...this.saleStatus,
            type: data.data?.SaleType,
            offerId: data.data?.offer_id,
            operationId: data.data?.operation_id,
            channel: this.checkCanalSaleBySaleType ? this.checkCanalSaleBySaleType(data.data?.SaleType) : this.campaignService.checkCanalSaleBySaleType(data.data?.SaleType)
          };
          if (this.setSaleStatus) {
            this.setSaleStatus(this.saleStatus);
          } else {
            this.campaignService.setSaleStatus(this.saleStatus);
          }
          if (data.data.SaleType == "F2F") {
            this.portalService.portalMode = "F2F";
            this.CTAEmailShow = data.data.ShowButton;
            this.googleAnalyticService.googleTaskManagerPush({
              event: "referrals_proccess",
              referrals_data: {
                sale_type: "F2F",
                flow_type: "draft_seller",
                seller_id: data.data.seller_id,
                operation_id: data.data.operation_id,
                transaction_date: data.data.transaction_date,
                quote_info: data.data.quote_info,
              },
            });
          }
          if (data.data.SaleType.startsWith("F2FRN")) {
            this.googleAnalyticService.googleTaskManagerPush({
              event: "referrals_proccess",
              referrals_data: {
                sale_type: "F2FRN",
                flow_type: "draft_client",
                seller_id: data.data.seller_id,
                operation_id: data.data.operation_id,
                transaction_date: data.data.transaction_date,
                quote_info: data.data.quote_info,
              },
            });
          }
          return {
            ...this.data
          };
        })
      );
  }

  getDraftDataError(input) {
    return this.httpClient
      .post<any>(`${env.baseUrl}druft`, input)
      .pipe(map((data) => data));
  }

  getHolidayData() {
    return this.holidayData;
  }

  setHolidayData(input) {
    this.holidayData = input;
  }

  getCampaignPromotion({ productId, date }) {
    return this.httpClient
      .get<any>(`${env.baseUrl}promotionCampaign/${productId}/${date}`)
      .pipe(
        map((data) => {
          this.setHolidayData(data);
          return data;
        })
      );
  }

  setParamsData(data) {
    this.paramsData = data;
  }
  getParamsData() {
    return this.paramsData;
  }

  getRangeAgeAmountsForBGEmail(productId, rangeAge) {
    const prod: any = this.productService
      .getProductsByRelatedName(productId, rangeAge)
      .find((e) => e.order == 1);
    let basico = prod.benefitLevels.find((e) => e.label == "Básico");
    let standard = prod.benefitLevels.find((e) => e.label == "Estándar");
    let premium = prod.benefitLevels.find((e) => e.label == "Premium");
    return {
      AmountBasic: `${basico.discountDollars}.${basico.discountCents}`,
      AmountStandard: `${standard.discountDollars}.${standard.discountCents}`,
      AmountPremium: `${premium.discountDollars}.${premium.discountCents}`,
    };
  }

  getParamsQueryString() {
    const params = this.getParamsData();
    const queryString = (
      params &&
      Object.keys(params)
        .map((key) => {
          return (
            encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
          );
        })
        .join("&")
    );
    return queryString ? `?${queryString}` : '';
  }

  updatePrefixUrlInLanding(snapshotData: any): void {
    const {prefixUrl} = this.saleStatus;
    const {path} = snapshotData;
    this.location.replaceState(
      `${path.replace(CampaignService.PREFIX_SALE.VL, prefixUrl)}`,
      this.getParamsQueryString()
    );
  }

  checkBackUrlFromBrowser(landingUrl: string, prefixUrl: string) {
    const OTPSuffixUrl = "/codigo-seguridad";
    const personalInfoSuffixUrl = "/datos-personales";
    if(this.currentUrl.includes(personalInfoSuffixUrl) && this.tryingUrl.includes(OTPSuffixUrl)) {
      return `${landingUrl}/cotizacion/${prefixUrl}/planes-precios`;
    }
  }
}
