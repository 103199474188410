import { ProductGTM, StepPageGTM } from '@app/models/commonGTM.model';

import { ActivatedRouteSnapshot } from '@angular/router';
import { CampaignService } from './campaign.service';
import { GoogleAnalyticServiceService } from '../helpers/google-analytic-service.service';
import { Injectable } from '@angular/core';
import { PortalService } from '@app/modules/home/services/portal.service';
import { SessionStorageService } from './session-storage.service';
import { TravelConstants } from '@app/modules/travel/travel.constants';
import { diffDays } from '@app/@shared/helpers/utils';

@Injectable() export class GtmTaggingService {
    
    private static GTM_USER_FLOW = 'user_flow';
    private static GTM_PRODUCT_LIST_ID = '1';
    private static GTM_PRODUCT_LIST_NAME = 'Plans section';
    private static identificationName = 'Cédula';
    private static otpName = 'OTP';
    
    constructor(private googleAnalyticService: GoogleAnalyticServiceService, private _sessionSt: SessionStorageService, public portalService: PortalService, private campaignService: CampaignService) {
        
    }

    gtmLandingEvent(shot: ActivatedRouteSnapshot, data: ProductGTM) {
        const saleStatusData = this.campaignService.getSaleStatus();
        this._sessionSt.set_SessionStorageFromObject({
            portalMode: this.portalService.portalMode,
            partner:"CHUBB",
            market: "",
            channel: saleStatusData.channel,
            flowType: saleStatusData.prefixUrl,
            product: data.product,
            language: "es-EC",
            platform: data.platform,
            vertical: data.vertical,
            region: "LATAM",
            policySystem: "S6",
            step: 1,
            stepName: "Inicio"
        });
        this.googleAnalyticService.googleTaskManagerPush({
            event: "PageView",
            pagePath: window.location.pathname,
            pageTitle: shot.data.title
        });
        this.googleAnalyticService.googleTaskManagerPush({
            event: 'landing_page',
        });
    }

    gtmLandingPage(shot: ActivatedRouteSnapshot) {
        const saleStatusData = this.campaignService.getSaleStatus();
        this._sessionSt.set_SessionStorageFromObject({
            portalMode: this.portalService.portalMode,
            partner:"CHUBB",
            market: "",
            channel: saleStatusData.channel,
            flowType: saleStatusData.prefixUrl,
            product: "TRAVEL",
            language: "es-EC",
            platform: "BG",
            vertical: "AH",
            region: "LATAM",
            policySystem: "S6",
            step: 1,
            stepName: "Inicio"
        });
        this.googleAnalyticService.googleTaskManagerPush({
            event: "PageView",
            pagePath: window.location.pathname,
            pageTitle: shot.data.title
        });
        this.googleAnalyticService.googleTaskManagerPush({
            event: 'landing_page',
        });
    }

    gtmPageEvent(shot: ActivatedRouteSnapshot, step: any) {
        this._sessionSt.set_SessionStorageFromObject({
            portalMode: this.portalService.portalMode,
            step: step.number,
            stepName: step.name
        });
        this.googleAnalyticService.googleTaskManagerPush({
            event: "PageView",
            pagePath: window.location.pathname,
            pageTitle: shot.data.title
        });
    }

    gtmWifiCallInit(ctas: any[]) {
        const label = ctas.find(e => e.status.find(s => s == TravelConstants.defaultWifiCallStatus))?.label || TravelConstants.wifiCallActionLabel;
        this.googleAnalyticService.googleTaskManagerPush({
            event: GtmTaggingService.GTM_USER_FLOW,
            category: "clicks_buttons",
            action: "click_button_call",
            label: label
        });
    }

    gtmTripType() {
        this._sessionSt.set_SessionStorageFromObject({
            portalMode: this.portalService.portalMode,
            destinationType: "",
            travelType: "",
            step: 2,
            stepName: "Tipo de viaje"
        });
        this.googleAnalyticService.googleTaskManagerPush({
            event: "PageView",
            pagePath: window.location.pathname,
            pageTitle: "Travel: Tipo de viaje"
        });
        this.googleAnalyticService.googleTaskManagerPush({
            event: 'quotation_started',
        });
    }

    gtmTripTypeAction(destinationType, tripType) {
        this._sessionSt.set_SessionStorageFromObject({
            destinationType: destinationType === 'national' ? 'Viaje nacional' : 'Viaje internacional',
            travelType: tripType === 'one-way' ? 'Solo ida' : 'Ida y regreso',
        });
    }
    
    gtmTripData() {
        this._sessionSt.set_SessionStorageFromObject({
            portalMode: this.portalService.portalMode,
            origin: "",
            destination: "",
            startDate: "",
            endDate: "",
            additionaltravellers: "",
            principalAge: "",
            secondaryAge: "",
            insuredDuration: "",
            leadTime: "",
            step: 3,
            stepName: "Datos del viaje"
        });
        this.googleAnalyticService.googleTaskManagerPush({
            event: "PageView",
            pagePath: window.location.pathname,
            pageTitle: "Travel: Datos del viaje"
        });
    }

    gtmTripDataAction(countryRegion = null) {

        const travelData = JSON.parse(localStorage.getItem('travelData'));
        try {
            if (travelData.destinationType === 'national') {
                this._sessionSt.set_SessionStorageFromObject({
                    origin: "Ecuador",
                    destination: "Ecuador",
                    destinationRegion: TravelConstants.latamRegion
                })
            }
    
            if (travelData.destinationType === 'international') {
                this._sessionSt.set_SessionStorageFromObject({
                    origin: "Ecuador",
                    destination: travelData.destinations.toString(),
                    destinationRegion: countryRegion
                })
            }
    
            if (travelData.tripType === 'one-way') {
                this._sessionSt.set_SessionStorageFromObject({
                    startDate: this.gtmFormatDate(travelData.tripDate),
                    endDate: "",
                    leadTime: diffDays(new Date().toString(), travelData.tripDate).toString(),
                    insuredDuration: 1
                })
            }
    
            if (travelData.tripType === 'round-trip') {
                this._sessionSt.set_SessionStorageFromObject({
                    startDate: this.gtmFormatDate(travelData.tripDates.start),
                    endDate: this.gtmFormatDate(travelData.tripDates.end),
                    leadTime: diffDays(new Date().toString(), travelData.tripDates.start).toString(),
                    insuredDuration: +diffDays(travelData.tripDates.start, travelData.tripDates.end).toString()
                })
            }
    
            if (travelData.numberPassengers) {
                const principalAgeVal = (travelData.numberPassengers.array).shift();
                this._sessionSt.set_SessionStorageFromObject({
                    additionaltravellers: travelData.numberPassengers.qty ? Number(travelData.numberPassengers.qty) - 1 : 0,
                    principalAge: principalAgeVal.toString(),
                    secondaryAge: travelData.numberPassengers.array.toString()
                ,})
            }
        } catch(e) {}
        
    }
    
    gtmPlans() {
        this._sessionSt.set_SessionStorageFromObject({
            portalMode: this.portalService.portalMode,
            step: 5,
            stepName: "Planes y precios"
        });
        this.googleAnalyticService.googleTaskManagerPush({
            event: "PageView",
            pagePath: window.location.pathname,
            pageTitle: "Travel: Planes y precios"
        });
    }

    gtmQuoteCompletion(plans) {
        this.googleAnalyticService.googleTaskManagerPush({
            event: 'generated_quotation',
        });

        let planListGmt = [];
        try {
            for (let plan of plans.plans) {
                let objPlan = {
                    planId: plan.rawData.typePlan,
                    planName: plan.title,
                    totalPremium: plan.rawData.amountPrime,
                    totalPremiumAnnual: plan.rawData?.amountAnnualPrime,
                    productListId: plan.rawData.code,
                    productListName: GtmTaggingService.GTM_PRODUCT_LIST_NAME
                }
                planListGmt.push(objPlan)
            }
        } catch(e) {}
        
        this._sessionSt.set_SessionStorageFromObject({
            plans: planListGmt,
            tax: ""
        });

        this.googleAnalyticService.googleTaskManagerPush({
            event: 'product_impressions',
        });
    }

    gtmViewDetail() {
        this.googleAnalyticService.googleTaskManagerPush({
            event: 'view_detail',
        });
    }

    gtmPlansAction(plan, addToCart = false) {
        const {namePlan, amountPrime, typePlan} = plan;
        this._sessionSt.set_SessionStorageFromObject({
            selectedPlanId: typePlan,
            selectedPlan: namePlan,
            selectedTotalPremium: amountPrime,
            paymentFrequency: TravelConstants.paymentFrequencyName,
            totalPremiumAnnual: "",
            value: amountPrime
        });
        if (addToCart) {
            this.googleAnalyticService.googleTaskManagerPush({
                event: 'add_to_cart',
            });
        }
    }

    gtmPlansEvent(plan, addToCart = false) {
        const {namePlan, amountPrime, amountAnnualPrime, typePlan, frequencyName} = plan;
        this._sessionSt.set_SessionStorageFromObject({
            selectedPlanId: typePlan,
            selectedPlan: namePlan,
            selectedTotalPremium: amountPrime,
            paymentFrequency: frequencyName,
            totalPremiumAnnual: amountAnnualPrime,
            value: amountPrime
        });
        if (addToCart) {
            this.googleAnalyticService.googleTaskManagerPush({
                event: 'add_to_cart',
            });
        }
    }
    
    gtmIdentification() {
        this._sessionSt.set_SessionStorageFromObject({
            portalMode: this.portalService.portalMode,
            step: 6,
            stepName: "Identificación"
        });
        this.googleAnalyticService.googleTaskManagerPush({
            event: "PageView", 
            pagePath: window.location.pathname, 
            pageTitle: "Travel: Identificación"
        });
    }

    gtmIdentificationSuccessLogin(idNumber: string) {
        this.googleAnalyticService.googleTaskManagerPush({
            event: "login",
            method: GtmTaggingService.identificationName,
            identificationNumber: idNumber
        });
    }

    gtmSecurityCode() {
        this._sessionSt.set_SessionStorageFromObject({
            step: 7,
            stepName: "Código de seguridad"
        });
        this.googleAnalyticService.googleTaskManagerPush({
            event: "PageView",
            pagePath: window.location.pathname,
            pageTitle: "Travel: Código de seguridad"
        });
    }

    gtmOTPSuccessLogin(otpNumber: string) {
        this.googleAnalyticService.googleTaskManagerPush({
            event: "login",
            method: GtmTaggingService.otpName,
            identificationNumber: otpNumber
        });
    }

    gtmPersonalData() {
        this._sessionSt.set_SessionStorageFromObject({
            step: 8,
            stepName: "Datos personales" 
        });
        this.googleAnalyticService.googleTaskManagerPush({
            event: "PageView", 
            pagePath: window.location.pathname, 
            pageTitle: "Travel: Datos personales"
        });
    }

    gtmPersonalDataAction() {
        this.googleAnalyticService.googleTaskManagerPush({
            event: 'begin_checkout',
        });
    }

    gtmPaymentMethod() {
        this._sessionSt.set_SessionStorageFromObject({
            paymentType: "",
            paymentOption: "",
            step: 9,
            stepName: "Método de pago"
        });
        this.googleAnalyticService.googleTaskManagerPush({
            event: "PageView",
            pagePath: window.location.pathname,
            pageTitle: "Travel: Método de pago"
        });
    }

    gtmSelectPaymentMethod(data, payFrequency: string) {
        const {type} = data;
        this._sessionSt.set_SessionStorageFromObject({
            paymentType: true,
            paymentOption: `${type}|${payFrequency}`
        });
    }

    gtmPaymentMethodAction() {
        this.googleAnalyticService.googleTaskManagerPush({
            event: 'purchase_intention',
        });
    }

    gtmPurchaseEvent(shot: ActivatedRouteSnapshot, step: StepPageGTM, transactionID: string) {
        this._sessionSt.set_SessionStorageFromObject({
            transactionID: transactionID,
            step: step.number,
            stepName: step.name
        });
        this.googleAnalyticService.googleTaskManagerPush({
            event: "PageView",
            pagePath: window.location.pathname,
            pageTitle: shot.data.title
        });
        this.googleAnalyticService.googleTaskManagerPush({
            event: "purchase",
            transactionID: transactionID
        });
    }

    gtmActiveInsurance(transactionID: string) {
        this._sessionSt.set_SessionStorageFromObject({
            transactionID: transactionID,
            step: 10,
            stepName: "Seguro activo"
        });
        this.googleAnalyticService.googleTaskManagerPush({
            event: "PageView", 
            pagePath: window.location.pathname,
            pageTitle: "Travel: Seguro activo"
        });
        this.googleAnalyticService.googleTaskManagerPush({
            event: "purchase",
            transactionID: transactionID
        });
    }

    gtmContinueClick() {
        this.googleAnalyticService.googleTaskManagerPush({
            event: GtmTaggingService.GTM_USER_FLOW,
            category: "clicks_buttons",
            action: "click_continue"
        });
    }
    gtmReturnClick() {
        this.googleAnalyticService.googleTaskManagerPush({
            event: GtmTaggingService.GTM_USER_FLOW,
            category: "clicks_links",
            action: "click_back"
        });
    }

    gtmError(errMsg: string) {
        this.googleAnalyticService.googleTaskManagerPush({
            event: GtmTaggingService.GTM_USER_FLOW,
            category: "system_message",
            action: "error",
            label: errMsg
        });
    }

    private gtmFormatDate(dateString: string) {
        const tmpDate = new Date(dateString);
        return [
            this.gtmFormatDate2Digits(tmpDate.getDate()),
            this.gtmFormatDate2Digits(tmpDate.getMonth() + 1),
            tmpDate.getFullYear(),
          ].join('/');
    }
    
    private gtmFormatDate2Digits(num: number) {
        return num.toString().padStart(2, '0');
    }
    
}