import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as env } from "../../environments/environment";
import { map, Observable } from 'rxjs';
import { CampaignService } from './campaign.service';
import { SessionStorageService } from './session-storage.service';

export interface ITotalPremium
{
  offerId: string;
  premiumAmount: number[];
  plan?: string;
}

export interface NewTransaction
{
  OfferId?: string;
  Fullname: string;
  Identification: string;
  Email: string;
  PhoneNumber: string;
  SaleType: string;
  RamoName?: string;
  ProductID: string;
}

@Injectable({
  providedIn: 'root'
})
export class TransactionService {
  private _leadData: NewTransaction;
  constructor(
    private httpClient: HttpClient,
    private campaignService: CampaignService
  ) { }

  public get leadData() {
    return this._leadData;
  }

  public set leadData(data: NewTransaction) {
    this._leadData = data;
  }

  updateRecotization(data: ITotalPremium): Observable<any> {
    return this.httpClient.post<any>(`${env.baseUrl}recotization`, data);
  }

  updateTransactionEntry(data: any): Observable<any> {
    return this.httpClient.post<any>(`${env.baseUrl}updTrans`, data);
  }

  createTransactionEntry(data: NewTransaction): Observable<any> {
    return this.httpClient.post<any>(`${env.baseUrl}newTrans`, data)
      .pipe(
        map((res) => {
          this.campaignService.setData({
            id_offer: res.offerId,
            SaleType: data.SaleType
          });
          this.campaignService.setSaleStatus({
            offerId: res.offerId,
            type: data.SaleType
          })
          return data;
        })
      );
  }
}
